import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import appendScript from '../../utils/appendScript';
import removeScript from '../../utils/removeScript';
import OwlCarousel from 'react-owl-carousel2';
import $ from 'jquery';
import { lang } from '../../actions/language';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			screenWidth: 0,
		};
		window.addEventListener('resize', this.updateScreenRes);
	}
	componentDidMount() {
		this.updateScreenRes();
	}
	updateScreenRes = () => {
		this.setState({
			screenWidth: window.innerWidth,
		});
	};

	handleClick(result) {
		localStorage.setItem('clickedValue', result.subCategoryName); // Set the clicked value in local storage
	  }

	render() {
		const {
			footerText,
			contactText,
			applicationName,
			applicationEmail,
			contactNo,
			socialLinksList,
			footer1,
			footer2,
		} = this.props;
		const { isAuthenticated } = this.props.auth;

		var socialRender = socialLinksList.map((result) => {
			return (
				<li>
					<a href={result.url} target="_blank">
						<i className={result.icon}></i>
					</a>
				</li>
			);
		});
		var footer1Content = footer1.map((result) => {
			return (
				<li>
					<Link
						to={`/marketplace?subcategoryID=${result._id}&categoryID=${result.categoryID}&slider=1`}
					>
						{result.subCategoryName}
					</Link>
				</li>
			);
		});

		var footer2Content = footer2.map((result) => {
			return (
				<li >
					<Link
						// to={`/shop?subcategoryID=${result._id}&categoryID=${result.categoryID}&slider=2`}
						to={`/?id=${result._id}&categoryId=${result.categoryID}`}
						onClick={() => this.handleClick(result)}
					>
						{result.subCategoryName}
					</Link>
				</li>
			);
		});

		return (
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="CTA">
								<h2>{footerText}</h2>
								{this.state.screenWidth > 700 && (
									<Link to="/shop?categoryID=60b4ca4ac2b47f2d066cd27d&slider=2">
										START HERE <i className="fas fa-chevron-right" />
									</Link>
								)}
								{this.state.screenWidth < 700 && (
									<Link to="/projectBuilder">START NOW</Link>
								)}{' '}
							</div>
						</div>
						{this.state.screenWidth < 700 && (
							<div className="col-lg-6 social">
								<ul>{socialLinksList.length > 1 && socialRender}</ul>
							</div>
						)}
						<div className="col-lg-6">
							<div className="q_links">
								<div className="row">
									<div className="col-lg-4">
										<h4>Shop</h4>
										<ul>{footer1Content}</ul>
									</div>
									<div className="col-lg-4">
										<h4>Solutions</h4>
										<ul>{footer2Content}</ul>
									</div>
									<div className="col-lg-4">
										<h4>About</h4>
										<ul>
											<li>
												<Link to="/whyovertone">
													Why Overtone?{' '}
												</Link>
											</li>
											<li>
												<Link to="/acoustic101">
													Acoustics 101{' '}
												</Link>
											</li>
											<li>
												<Link to="/support">Support</Link>
											</li>
											<li>
												<Link to="/faq">FAQ </Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="contact">
								<h4>Contact</h4>
								<p>{contactText}</p>

								<p>{contactNo}</p>
								<p>{applicationEmail}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="copyrights">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 copy">
								<p>
									© {new Date().getFullYear()} {applicationName} All
									rights reserved.
								</p>
							</div>

							{/* {(this.state.screenWidth > 700) && <div className="col-lg-6 social">
                  <ul>
                  {socialLinksList.length>1 && socialRender}
                  </ul>
                </div>} */}

							<div className="col-lg-5 social">
								<ul>{socialLinksList.length > 1 && socialRender}</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
Footer.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};
Footer.defaultProps = {
	footerText: '',
	contactText: '',
	applicationName: '',
	applicationEmail: '',
	contactNo: '',
	socialLinksList: [],
	footer1: [],
	footer2: [],
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
	cart: state.cart,
	wishlist: state.wishlist,
});

export default connect(mapStateToProps, {})(withRouter(Footer));
