import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMAGE_URL } from "../../actions/constant";
import LoginModal from "../auth/LoginModal";
import Currency from "../common/Currency";
import { logoutUser } from "../../actions/authAction";
import Dropdown from "react-bootstrap/Dropdown";

const queryString = require("query-string");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      showMenu: false,
      showMenuRoute: false,
      showCart: false,
      showLogin: false,
      subCategoryList: [],
      subCategoryChildList: [],
      subCategoryID: "",
      isLoginPage: true,
      allowedSize: true,
      mobileMenu: false,
      solutionClass: "",
      screenWidth: 0,
      subCategoryClass: "",
    };
    window.addEventListener("resize", this.updateScreenRes);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated && this.props.auth.isAuthenticated) {
      this.setState({
        showMenu: false,
        showCart: false,
        showLogin: false,
      });
    }
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        showMenuRoute: false,
      });
    }
  }

  componentDidUpdate() {
    const params = queryString.parse(location.search);
    if (params.categoryId && !this.state.showMenuRoute  && !this.state.showMenu) {
      this.setState({showMenuRoute: true})
      const element = document.getElementById(params.categoryId);
      element.click()
    }
  }
  componentDidMount() {
    this.updateScreenRes();
    if (this.props.location.pathname == "/login")
      this.setState({ isLoginPage: false });

    /// Below first two lines Added by Selva
    if (this.props.location.pathname == "/register")
      this.setState({ isLoginPage: false });
    ///////////////////////////////////////////
  }
  updateScreenRes = () => {
    this.setState({
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
    });
  };

  handleClickCategory = (e, categoryID, sliderStyle) => {
    e.stopPropagation();
    const { subcategory, subcategoryChild } = this.props;

    const subcategoryFilter = subcategory.filter(
      (x) => x.categoryID === categoryID
    );
    console.log(subcategory, subcategoryFilter, "subcategoryFilter");
    if (subcategoryFilter.length === 0) return;
    //GETTING SUBCATEGORY LIST
    const subcatgoryRender = subcategoryFilter.map((res) => {
      return (
        <Link
          className="link"
          to="#"
          id={res._id}
          key={res._id}
          onClick={(e) => {
            this.handleClickCategoryChild(e, res._id, sliderStyle, categoryID);
          }}
        >
          {res.subCategoryName}
        </Link>
      );
    });
    //GETTING SUBCATEGORY CHILD LIST FROM 1 ST SUBCATEGORY
    const subCategoryID = subcategoryFilter[3]._id;
    const subcategoryChildFilter = subcategoryChild.filter(
      (x) => x.subcategoryID === subCategoryID
    );

    const subcatgorychildRender = subcategoryChildFilter.map((res, index) => {
      return (
        <Link
          className="link-sub-cat"
          style={{ width: "100%" }}
          key={res._id}
          to={`/solutionsub?subcategoryChildID=${res._id}&categoryID=${categoryID}&slider=${sliderStyle}`}
          target="_parent"
        >
          <img src={`${IMAGE_URL}${res.photoUrl}`} alt="" />
          {res.subCategoryChildName}
        </Link>
      );
    });
    this.setState({
      showCart: false,
      showLogin: false,
      showMenu: !this.state.showMenu,
      subCategoryList: subcatgoryRender,
      subCategoryChildList: subcatgorychildRender,
      solutionClass: !this.state.showMenu ? "solutionClicked" : "",
      subCategoryClass: !this.state.showMenu ? "subCategoryClass" : "",
    });
  };

  handleClickCategoryChild = (e, subCategoryID, sliderStyle, categoryID) => {
    console.log(e, "event");
    e.preventDefault();
    const { subcategoryChild } = this.props;
    const subcategoryChildFilter = subcategoryChild.filter(
      (x) => x.subcategoryID === subCategoryID
    );
    console.log(subcategoryChildFilter, "subcategoryChildFilter");
    const subcatgorychildRender = subcategoryChildFilter.map((res) => {
      return (
        <Link
        key={res._id}
          to={`/solutionsub?subcategoryChildID=${res._id}&categoryID=${categoryID}&slider=${sliderStyle}`}
          target="_parent"
        >
          <img src={`${IMAGE_URL}${res.photoUrl}`} />
          <p>{res.subCategoryChildName}</p>
        </Link>
      );
    });
    this.setState({
      subCategoryChildList: subcatgorychildRender,
      subCategoryClass: "",
    });
  };

  onLogout(e) {
    e.preventDefault();
    this.props.logoutUser();
    localStorage.removeItem("cart");
    window.location.reload();
  }

  //outside
  handleClickFunction = () => {
    if (!this.state.showModal) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  handleOutsideClick = (e) => {
    if (!this.node.contains(e.target)) this.handleClickFunction();
  };

  sideButton(isAuth) {
    const { cart } = this.props;

    const val = cart;

    return (
      <div
        className="btn-s sideButton"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={(node) => {
          this.node = node;
        }}
      >
        {isAuth && (
          <div class="get-started-btn scrollto">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                type="button"
                onMouseEnter={() => {
                  this.setState({ showCart: false });
                }}
                id="dropdown-basic"
                class="get-started-btn btn  "
              >
                Account
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Link class="dropdown-item" to="/account">
                  Profile
                </Link>
                <Link class="dropdown-item" to="/order">
                  Orders
                </Link>
                <Link
                  class="dropdown-item"
                  to="javascript:void(0);"
                  onClick={(e) => this.onLogout(e)}
                >
                  Logout
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {!isAuth && this.state.isLoginPage && (
          <>
            <Link
              to="/login"
              // onClick={(e) => {
              //   this.handleClickFunction();
              //   e.preventDefault();
              //   this.setState({
              //     showMenu: false,
              //     showLogin: this.state.showLogin,
              //     showCart: false,
              //   });
              // }}
              className="get-started-btn scrollto"
            >
              {" "}
              SIGN IN{" "}
            </Link>
            {this.state.showModal && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "150px",
                  width: "50%",
                  height: "50%",
                }}
              >
                <LoginModal />
              </div>
            )}
          </>
        )}
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              showMenu: false,
              showLogin: false,
              showCart: !this.state.showCart,
              solutionClass: "",
            });
            if (isAuth) {
              localStorage.setItem("cart", JSON.stringify(val));
            }
          }}
          className="cart scrollto"
          target="_parent"
        >
          <i className="fas fa-shopping-cart" />
        </Link>
      </div>
    );
  }
  topNavBar(categoryRender) {
    const location = this.props.location;
    return (
      <ul className="topNavBar">
        {categoryRender}
        <li className="order-4">
          <Link
            to="/support"
            style={{ color: !this.state.showMenu && location.pathname === "/support" ? "#008aff" : "" }}
          >
            SUPPORT
          </Link>
        </li>
      </ul>
    );
  }
  subMenublock(subCategoryList, subCategoryChildList) {
    return (
      <div class="row" style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        <div class="col-md-2 col-lg-2 col-sm-2">
          <div className={`sub-menu-lists ${this.state.subCategoryClass}`}>
            {subCategoryList}
          </div>
        </div>
        <div class="col-md-10 col-lg-10 col-sm-10" style={{ width: "100%" }}>
          <ul class="sub-menu-img" style={{ width: "100%" }}>
            {subCategoryChildList}
          </ul>
        </div>
      </div>
    );
  }
  navBarShow() {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
      showMenu: false,
      showLogin: false,
      showCart: false,
    });
  }

  render() {
    const { category, logoUrl, headerClass } = this.props;
    const { cart } = this.props;
    const location = this.props.location;
    const totalCost = cart.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );

    const totalDiscount =
      cart.length > 0
        ? cart
            .map((data) => {
              if (data.discountAmount) {
                return data.discountAmount * data.quantity;
              } else {
                return (data.basePrice - data.discountPrice) * data.quantity;
              }
            })
            .reduce((acc, curr) => acc + curr)
        : 0;

    const { subCategoryList, subCategoryChildList, screenWidth } = this.state;
    const { isAuthenticated } = this.props.auth;

    const categoryRender = category.map((result) => {
      if (result.dropdown === "Yes") {
        return (
          <li key={result._id} className="mega-menu active order-1">
            <span
              id={result._id}
              onClick={(e) => {
                this.handleClickCategory(e, result._id, result.sliderStyle);
              }}
              className={`${this.state.solutionClass}`}
            >
              {result.categoryName}
            </span>
          </li>
        );
      } else {
        // return  <li><Link to={`/shop?categoryID=${result._id}&slider=${result.sliderStyle}`}>{result.categoryName}</Link></li>
        if (result._id == "60b4ca30c2b47f2d066cd27c")
          return (
            <li key={result._id} className="order-2">
              <Link
                to={`/marketplace?categoryID=${result._id}&slider=${result.sliderStyle}`}
                style={{
                  color: !this.state.showMenu && location.pathname === "/marketplace" ? "#008aff" : "",
                }}
              >
                {/* shop */}
                {result.categoryName}
              </Link>
            </li>
          );
        else if (result._id == "60b4ca4ac2b47f2d066cd27d")
          return (
            <li key={result._id} className="order-3">
              <Link
                to={`/shop?categoryID=${result._id}&slider=${result.sliderStyle}`}
                style={{
                  color: !this.state.showMenu && location.pathname === "/shop" ? "#008aff" : "",
                }}
              >
                {/* acq - art -panels */}
                {result.categoryName}
              </Link>
            </li>
          );
      }
    });
    const sideButton = this.sideButton(isAuthenticated);
    const topNavBar = this.topNavBar(categoryRender);
    const subMenuBlock = this.subMenublock(
      subCategoryList,
      subCategoryChildList
    );

    return (
      <React.Fragment>
        <header ref={this.wrapperRef} id="header" className={`${headerClass}`}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-12 d-flex align-items-center justify-content-between">
                <h1 className="logo">
                  <Link to="/">
                    <img src={`${IMAGE_URL}${logoUrl}`} />
                  </Link>
                </h1>
                {screenWidth > 700 && (
                  <nav className="nav-menu d-lg-block">{topNavBar}</nav>
                )}
                {screenWidth >= 769 && sideButton}
                {/* .nav-menu */}
                {screenWidth <= 769 && (
                  <div className="mobile_menu">
                    <div
                      className="mobile_menu_btn"
                      onClick={() => this.navBarShow()}
                    >
                      <label>&#8212;</label>
                      <label>&#8212;</label>
                      <label>&#8212;</label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.showMenu && (
            <div className="sub-menu-block">{subMenuBlock}</div>
          )}
        </header>
        {screenWidth <= 769 && this.state.mobileMenu && (
          <div className="mobile_menu_content">
            {sideButton}
            {topNavBar}
          </div>
        )}
        {screenWidth <= 769 && this.state.mobileMenu && this.state.showMenu && (
          <div className="mobile_sub_menu">{subMenuBlock}</div>
        )}
        {this.state.showCart && (
          <div id="myModal2" className="modal-div btn-s d-block">
            {/* Modal content */}
            <div className="top-al-cart">
              <img
                className="cart-up-arw"
                src="assets/img/icon/up.png"
                alt="img"
              />
              <div className="cart-modal">
                <div className="clearfix">
                  <ul className="firstli">
                    {cart.length > 0 ? (
                      cart.map((result) => {
                        let imgUrl;
                        let modifiedUrl;
                        if (result.image && result.image.length > 0) {
                          imgUrl = result.image;

                          modifiedUrl = imgUrl.replace(
                            "http://dev-overtone-accoustics.s3-website-us-east-1.amazonaws.com",
                            "https://s3.amazonaws.com/dev-overtone-accoustics"
                          );
                        } else {
                          return result.image;
                        }

                        return (
                          <li key={result.name} >
                            {/* <img src={result.image} /> */}
                            <img src={modifiedUrl} />
                            <div className="ti-pr">
                              <h4>{result.name}</h4>
                              <h5>
                                <span className="strickOut">
                                  <Currency />{" "}
                                  {parseFloat(result.quantity) *
                                    parseFloat(result.price)}
                                </span>{" "}
                                /{" "}
                                <span className="">
                                  <Currency />{" "}
                                  {parseFloat(result.quantity) *
                                    (parseFloat(result.price) -
                                      (parseFloat(result.basePrice) -
                                        parseFloat(result.discountPrice)))}
                                </span>
                              </h5>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <h4>No Products Added</h4>
                    )}
                  </ul>
                  <div className="line" />
                  {/* total */}
                  <ul className="total">
                    <li>
                      <h5>Total</h5>
                    </li>
                    <li>
                      <h3>
                        <Currency /> {totalCost - totalDiscount}
                      </h3>
                    </li>
                    {totalCost > 0 && (
                      <li>
                        <Link to="/cart">VIEW CART</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
Header.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};
Header.defaultProps = {
  currency: "$",
  category: [],
  logoUrl: "",
  subcategory: [],
  headerClass: "fixed-top1",
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  cart: state.cart,
  wishlist: state.wishlist,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Header));